import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import EnvironmentOpening from "../../../../assets/pages/controls/reports/environment-opening.mp4";
import DataFilters from "../../../../assets/pages/controls/reports/data-filters.mp4";
import SimpleExportToExcel from "../../../../assets/pages/controls/reports/simple-export-to-excel.mp4";
import DetailedExportToExcel from "../../../../assets/pages/controls/reports/detailed-export-to-excel.mp4";
import ExportToPDF from "../../../../assets/pages/controls/reports/export-to-pdf.mp4";
import Printing from "../../../../assets/pages/controls/reports/printing.mp4";

function Relatorios() {
  return (
    <section>
      <h1>Relatórios</h1>
      <p>
        Cada relatório permite a visualização de dados distintos a ser abordados
        mais adiante. Eles estão dentro de um mesmo ambiente e seus controles,
        detalhados abaixo, são muito similares.
      </p>
      <h2>Controles Gerais</h2>
      <h3>Abertura do Ambiente</h3>
      <p>
        Os relatórios estão disponíveis em um ambiente distinto. Para acessá-lo,
        é necessário apenas um clique no ícone na barra de navegação.
      </p>
      <VideoRendering>
        <source src={EnvironmentOpening} type="video/mp4" />
      </VideoRendering>
      <h3>Fitro de Dados</h3>
      <p>
        Todo relatório é composto de alguns parâmetros, como área, tipo de
        venda, período, entre outros. Com este controle, é possível filtrar os
        valores de cada parâmetro.
      </p>
      <VideoRendering>
        <source src={DataFilters} type="video/mp4" />
      </VideoRendering>
      <h2>Controles Específicos</h2>
      <h3>Exportação Simples para Excel</h3>
      <p>
        Se a visualização das informações essenciais presentes no relatório for
        suficiente, é possível exportá-las com o auxílio deste controle.
      </p>
      <VideoRendering>
        <source src={SimpleExportToExcel} type="video/mp4" />
      </VideoRendering>
      <h3>Exportação Detalhada para Excel</h3>
      <p>
        Se uma visualização mais detalhada das informações presentes no
        relatório for necessária, é possível exportá-las com o auxílio deste
        controle.
      </p>
      <VideoRendering>
        <source src={DetailedExportToExcel} type="video/mp4" />
      </VideoRendering>
      <h3>Exportação para PDF</h3>
      <p>
        Caso necessário, o relatório pode ser exportado para um documento PDF.
        Assim, salvar dados específicos para uma reunião e compartilhá-los
        torna-se extremamente conveniente.
      </p>
      <VideoRendering>
        <source src={ExportToPDF} type="video/mp4" />
      </VideoRendering>
      <h3>Impressão</h3>
      <p>
        Caso necessário, o relatório pode ser impresso. Assim, levar dados
        específicos para uma reunião e compartilhá-los torna-se extremamente
        conveniente.
      </p>
      <VideoRendering>
        <source src={Printing} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Relatorios;
