import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon

import BarChart from "../../../../assets/bar-chart.svg";
import PieChart from "../../../../assets/pie-chart.svg";
import Tabular from "../../../../assets/tabular.svg";

// Video
import Competitors from "../../../../assets/pages/layers/competitors.mp4";

function Concorrentes() {
  return (
    <section>
      <h1>Concorrentes</h1>
      <p>
        Esta camada permite a visualização da localização das concessionárias
        concorrentes.
      </p>
      <VideoRendering>
        <source src={Competitors} type="video/mp4" />
      </VideoRendering>
      <p>
        A visualização dos dados abaixo é feita com o auxílio do controle{" "}
        <a href="/controles/camadas#display-data">
          <strong>Visualizar Dados</strong>
        </a>{" "}
        desta camada.
      </p>
      <h3>Ranking do Grupo</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          market share de venda
        </span>{" "}
        dos diferentes grupos concorrentes nas regiões e período selecionados.
      </p>
      <h3>Ranking de Vendas</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de vendas
        </span>{" "}
        dos diferentes grupos concorrentes nas regiões e período selecionados.
      </p>
      <h3>Sumário</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          número de vendas
        </span>
        ,{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          média de vendas por mês
        </span>{" "}
        e{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          market share de venda
        </span>{" "}
        dos grupos concorrentes nas regiões e período selecionados.
      </p>
    </section>
  );
}

export default Concorrentes;
