import React, { Component } from "react";
import Button from "../assets/play-button.svg";

class VideoRendering extends Component {
  render() {
    const playVideo = () => {
      const video = this.refs.video;
      if (video.paused) video.play();
      else video.pause();
    };

    return (
      <div className="clip">
        <video ref="video">{this.props.children}</video>
        <ul className="video">
          <li>
            <img src={Button} alt="" onClick={playVideo} />
          </li>
          <li>
            <span onClick={playVideo}>Play</span>
          </li>
        </ul>
      </div>
    );
  }
}

export default VideoRendering;
