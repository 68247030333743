import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import Tabular from "../../../../assets/tabular.svg";

// Video
import FirstPage from "../../../../assets/pages/reports/registration-by-bs/first-page.mp4";
import SecondPage from "../../../../assets/pages/reports/registration-by-bs/second-page.mp4";

function RegistrationByBS() {
  return (
    <section>
      <h1>Registration by Brand and Segment</h1>
      <p>
        This report helps you view the registration history in a region by
        product segment and by brand.
      </p>
      <h3>First Page</h3>
      <p>
        With it, you can observe the industry’s{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          number of registrations
        </span>{" "}
        and your brand's, as well as your brand's{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          registration market share
        </span>{" "}
        by product segment in the selected region and period.
      </p>
      <VideoRendering>
        <source src={FirstPage} type="video/mp4" />
      </VideoRendering>
      <h3>Second Page</h3>
      <p>
        With it, you can observe the{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          number of registrations
        </span>{" "}
        by flag in the selected region and period.
      </p>
      <VideoRendering>
        <source src={SecondPage} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default RegistrationByBS;
