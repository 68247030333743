import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";
import PieChart from "../../../../assets/pie-chart.svg";
import Tabular from "../../../../assets/tabular.svg";

// Video
import NetworkVideo from "../../../../assets/pages/layers/network.mp4";

function Network() {
  return (
    <section>
      <h1>Network</h1>
      <p>This layer helps you view the location of your brand's dealers.</p>
      <VideoRendering>
        <source src={NetworkVideo} type="video/mp4" />
      </VideoRendering>
      <p>
        The visualization of the data below is done with the aid of the{" "}
        <a href="/controls/layers#display-data">
          <strong>Display Data</strong>
        </a>{" "}
        control of this layer.
      </p>
      <h3>Group Ranking</h3>
      <p>
        The ranking of your brand's{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          sales market share
        </span>{" "}
        in the selected region and period.
      </p>
      <h3>Sales Ranking</h3>
      <p>
        The ranking of your brand's{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of sales
        </span>{" "}
        in the selected region and period.
      </p>
      <h3>Summary</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          number of sales
        </span>
        ,{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          average monthly sales
        </span>{" "}
        and{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          sales market share
        </span>{" "}
        of your brand's dealers in the selected region and period.
      </p>
    </section>
  );
}

export default Network;
