import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import EnableLayer from "../../../../assets/pages/controls/layers/enable-layer.mp4";
import DisplayData from "../../../../assets/pages/controls/layers/display-data.mp4";
import DataFilters from "../../../../assets/pages/controls/layers/data-filters.mp4";
import UndoChanges from "../../../../assets/pages/controls/layers/undo-changes.mp4";
import HeatMap from "../../../../assets/pages/controls/layers/heat-map.mp4";
import Clutter from "../../../../assets/pages/controls/layers/clutter.mp4";
import Declutter from "../../../../assets/pages/controls/layers/declutter.mp4";
import Range from "../../../../assets/pages/controls/layers/range.mp4";
import Pins from "../../../../assets/pages/controls/layers/pins.mp4";
import DisplayReportedStores from "../../../../assets/pages/controls/layers/display-reported-stores.mp4";
import Period from "../../../../assets/pages/controls/layers/period.mp4";
import AverageMonthlySales from "../../../../assets/pages/controls/layers/average-monthly-sales.mp4";
import DisplayStoreName from "../../../../assets/pages/controls/layers/display-store-name.mp4";
import SelectRegion from "../../../../assets/pages/controls/layers/select-region.mp4";
import SelectData from "../../../../assets/pages/controls/layers/select-data.mp4";
import DisplayLocaleName from "../../../../assets/pages/controls/layers/display-locale-name.mp4";
import DisplayThemeValue from "../../../../assets/pages/controls/layers/display-theme-value.mp4";

function Camadas() {
  return (
    <section>
      <h1>Camadas</h1>
      <p>
        Cada camada permite a visualização de dados distintos a ser abordados
        mais adiante. Os controles, detalhados abaixo, são muito similares e
        permitem a operação e customização de todas as camadas.
      </p>
      <h2>Controles Gerais</h2>
      <h3>Ativar Camadas</h3>
      <p>
        Para que os dados de uma camada sejam expostos no mapa temático, faz-se
        necessário que ela esteja ativada. Com este controle, é possível
        ativá-la em apenas um clique.
      </p>
      <VideoRendering>
        <source src={EnableLayer} type="video/mp4" />
      </VideoRendering>
      <h3 id="display-data">Visualizar Dados</h3>
      <p>
        Os dados de uma camada se encontram não só no mapa temático, mas também
        em forma tabular e gráfica. Com este controle, é possível visualizá-los
        em apenas um clique.
      </p>
      <VideoRendering>
        <source src={DisplayData} type="video/mp4" />
      </VideoRendering>
      <h3>Filtrar de Dados</h3>
      <p>
        Os dados de uma camada podem ser filtrados por grupos, segmentos, entre
        outros. Com este controle, é possível filtrá-los em dois cliques.
      </p>
      <VideoRendering>
        <source src={DataFilters} type="video/mp4" />
      </VideoRendering>
      <h3>Desfazer Alterações</h3>
      <p>
        Desfazer todas as alterações feitas em uma camada pode ser essencial
        depois de uma análise. Com este controle, é possível desfazê-las em
        apenas um clique.
      </p>
      <VideoRendering>
        <source src={UndoChanges} type="video/mp4" />
      </VideoRendering>
      <h2>Detalhamento</h2>
      <p>
        Existem controles mais específicos em todas as camadas, a ser explorados
        abaixo, que permitem uma visão mais detalhada dos dados presentes no
        mapa temático.
      </p>
      <h3>Mancha de Calor</h3>
      <p>
        Com este controle, os dados presentes no mapa temático podem ser
        observados em relação à concentração em uma região.
      </p>
      <VideoRendering>
        <source src={HeatMap} type="video/mp4" />
      </VideoRendering>
      <h3>Clusterizar</h3>
      <p>
        Com este controle, é possível clusterizar, ou agrupar, os dados
        presentes no mapa temático que se encontram desclusterizados, ou
        desagrupados.
      </p>
      <VideoRendering>
        <source src={Clutter} type="video/mp4" />
      </VideoRendering>
      <h3>Desclusterizar</h3>
      <p>
        Com este controle, é possível desclusterizar, ou desagrupar, os dados
        presentes no mapa temático que se encontram clusterizados, ou agrupados.
      </p>
      <VideoRendering>
        <source src={Declutter} type="video/mp4" />
      </VideoRendering>
      <h3>Range</h3>
      <p>
        Em relação aos três controles anteriores, existe uma notável variação de
        intensidade na qual o dado pode ser representado. Com este controle, é
        possível modificar a intensidade.
      </p>
      <VideoRendering>
        <source src={Range} type="video/mp4" />
      </VideoRendering>
      <h3>Pins</h3>
      <p>
        Com este controle, é possível alterar o tamanho dos pins das
        concessionárias presentes no mapa temático.
      </p>
      <VideoRendering>
        <source src={Pins} type="video/mp4" />
      </VideoRendering>
      <h3>Exibir Lojas Reportadas</h3>
      <p>
        As concessionárias com divergência de dados cadastrais, localização ou
        mesmo sua existência podem ser reportadas pelos usuários. Com este
        controle, é possível localizar as concessionárias reportadas.
      </p>
      <VideoRendering>
        <source src={DisplayReportedStores} type="video/mp4" />
      </VideoRendering>
      <p>
        No cenário disposto acima, não há concessionária da sua marca reportada
        no país.
      </p>
      <h3>Período</h3>
      <p>
        Todos os dados apresentados no mapa temático concernem a determinado
        período. Com este controle, é possível defini-lo.
      </p>
      <VideoRendering>
        <source src={Period} type="video/mp4" />
      </VideoRendering>
      <h3>Vende Média Mensal</h3>
      <p>
        A soma da quantidade de veículos vendidos por uma concessionária em
        determinado período permite a visualização da venda média mensal, ou
        VVM, dela. Com este controle, é possível visualizar a média de uma
        concessionária.
      </p>
      <VideoRendering>
        <source src={AverageMonthlySales} type="video/mp4" />
      </VideoRendering>
      <h3>Exibir Nome das Lojas</h3>
      <p>
        Com este controle, é possível visualizar o nome de cada concessionária
        presente no mapa temático.
      </p>
      <VideoRendering>
        <source src={DisplayStoreName} type="video/mp4" />
      </VideoRendering>
      <h2>Controles Específicos</h2>
      <p>
        As camadas{" "}
        <a href="/camadas/limites-territoriais">
          <strong>Limites Territoriais</strong>
        </a>{" "}
        e{" "}
        <a href="/camadas/areas">
          <strong>Áreas</strong>
        </a>{" "}
        possuem controles, a ser detalhados abaixo, extremamente similares, no
        entanto, distintos dos controles das demais camadas da plataforma.
      </p>
      <h3>Delimitar Geometrias</h3>
      <p>
        Com este controle, é possível definir determinada geometria a ser
        apresentada para análise.
      </p>
      <VideoRendering>
        <source src={SelectRegion} type="video/mp4" />
      </VideoRendering>
      <h3>Selecionar Dados</h3>
      <p>
        Com este controle, é possível definir o dado ser apresentado para
        análise.
      </p>
      <VideoRendering>
        <source src={SelectData} type="video/mp4" />
      </VideoRendering>
      <h3>Exibir Nome das Localidades</h3>
      <p>
        Com este controle, é possível visualizar o nome de cada região presente
        no mapa temático.
      </p>
      <VideoRendering>
        <source src={DisplayLocaleName} type="video/mp4" />
      </VideoRendering>
      <h3>Exibir Valores do Tema</h3>
      <p>
        Com este controle, é possível visualizar os valores de cada tema
        presente no mapa temático.
      </p>
      <VideoRendering>
        <source src={DisplayThemeValue} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Camadas;
