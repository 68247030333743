import axios from 'axios';

export default class HttpService {
  constructor() {
  }

  get http() {
    const instance = axios.create({
      headers: this.getHeaders()
    });

    //instance.interceptors.request.use(this._requestSuccess, this._requestError);
    //instance.interceptors.response.use(this._responseSuccess, this._responseError);

    return instance;
  }

  json() {
    const instance = axios.create();
    return instance;
  }

  getHeaders(username = null, token = null) {
    return { Authorization: `Bearer ${token || sessionStorage.getItem('token')}`,
    }
  }

  // _requestSuccess(req) {
  //   if (req.url !== `${CONFIG.URL.NEODASH_API}/api/notification`)
  //     store.dispatch(ENABLE_LOADING);
  //   return req;
  // }
  //
  // _requestError(err) {
  //   store.dispatch(DISABLE_LOADING);
  //   return Promise.reject(err);
  // }
  //
  // _responseSuccess(res) {
  //   store.dispatch(DISABLE_LOADING);
  //   return Promise.resolve(res);
  // }
  //
  // _responseError(err) {
  //   if (err.request.status === 401) {
  //     auth.login();
  //   }
  //
  //   store.dispatch(DISABLE_LOADING);
  //   return Promise.reject(err);
  // }
}
