import React, {Component} from "react";
import axios from 'axios';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./App.css";

import Home from './components/home';
// Docs Components
// Components
import Header from "./components/header";
import Sidebar from "./components/sidebar";

// Componets > Pages > Portuguese
import Interface from "./components/pages/portuguese/interface";
import Suporte from "./components/pages/portuguese/suporte";

import Camadas from "./components/pages/portuguese/controles/camadas";
import Relatorios from "./components/pages/portuguese/controles/relatorios";

import Areas from "./components/pages/portuguese/camadas/areas";
import Clientes from "./components/pages/portuguese/camadas/clientes";
import ClusterDeVendas from "./components/pages/portuguese/camadas/cluster-de-vendas";
import Concorrentes from "./components/pages/portuguese/camadas/concorrentes";
import DNC from "./components/pages/portuguese/camadas/dnc";
import LimitesTerritoriais from "./components/pages/portuguese/camadas/limites-territoriais";
import Mapas from "./components/pages/portuguese/camadas/mapas";
import Rede from "./components/pages/portuguese/camadas/rede";
import VendaDireta from "./components/pages/portuguese/camadas/venda-direta";
import Voronoi from "./components/pages/portuguese/camadas/voronoi";

import MarketBreakdown from "./components/pages/portuguese/relatorios/market-breakdown";
import CestaDeComparacao from "./components/pages/portuguese/relatorios/cesta-de-comparacao";
import EmplacamentoPorBS from "./components/pages/portuguese/relatorios/emplacamento-por-bs";
import SegmentoModeloVersao from "./components/pages/portuguese/relatorios/segmento-modelo-versao";

// Componets > Pages > English
import InterfaceEnglish from "./components/pages/english/interface";
import Support from "./components/pages/english/support";

import Layers from "./components/pages/english/controls/layers";
import Reports from "./components/pages/english/controls/reports";

import AreasEnglish from "./components/pages/english/layers/areas";
import Clients from "./components/pages/english/layers/clients";
import SalesCluster from "./components/pages/english/layers/sales-cluster";
import Competitors from "./components/pages/english/layers/competitors";
import UMD from "./components/pages/english/layers/umd";
import TerritorialBoundaries from "./components/pages/english/layers/territorial-boundaries";
import Maps from "./components/pages/english/layers/maps";
import Network from "./components/pages/english/layers/network";
import DirectSales from "./components/pages/english/layers/direct-sales";
import VoronoiEnglish from "./components/pages/english/layers/voronoi";

import MarketBreakdownEnglish from "./components/pages/english/reports/market-breakdown";
import ComparisonBasket from "./components/pages/english/reports/comparison-basket";
import RegistrationByBS from "./components/pages/english/reports/registration-by-bs";
import SegmentModelVersion from "./components/pages/english/reports/segment-model-version";

// Guide Components
import GuideList from "./components/guide/guide-list";
import GuideDetail from "./components/guide/guide-detail";
import AuthService from "./services/auth.service";
import HttpService from "./services/http.service";

const keyToken = 'token';
const _dictLanguages = {
  'pt-AS': 'pt-BR',
  'pt-BR': 'pt-BR',
  'en-AS': 'en-US',
  'en-BR': 'en-US',
};

const _language = {
  setCurrent(name) {
    this.current = _dictLanguages[name];
  },
  current: 'pt-BR'
};

class App extends Component {
  getUrlNeogig = (urlLocalhost = 'https://www.neogig.com.br') => {
    const urls = [
      {host: 'manual.neogig.com.ar', urlNeogig: 'https://neogig-api.neogig.com.ar'},
      {host: 'manual.neogig.com.ec', urlNeogig: 'https://neogig-api.neogig.com.ec'},
      {host: 'manual.neogig.com.co', urlNeogig: 'https://neogig-api.neogig.com.co'},
      {host: 'manual.neogig.com.pe', urlNeogig: 'https://neogig-api.neogig.com.pe'},
      {host: 'manual.neogig.com.br', urlNeogig: 'https://neogig-api.neogig.com.br'},
      {host: 'manual.neogig.cl', urlNeogig: 'https://neogig-api.neogig.cl'},
      {host: 'qa.manual.neogig.com.br', urlNeogig: 'http://qa.neogig.com.br'},
      {host: 'dv.manual.neogig.com.br', urlNeogig: 'http://dv.neogig.com.br'},
      {host: 'localhost', urlNeogig: urlLocalhost},
    ];

    const hostNeogig = urls.find(x => x.host === window.location.hostname) || urls[0];
    return hostNeogig.urlNeogig;
  };

  getUserInfo() {
    const query = new URLSearchParams(window.location.search);
    const token = sessionStorage.getItem(keyToken);

    const email = sessionStorage.getItem('username');
    const lang = query.get('lang') || sessionStorage.getItem('language');

    return {token, email, lang};
  }

  updateUserInfo = (token, email, client, language) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('client', client);
  };

  isLogged() {
    const auth = new AuthService();
    auth.isAuthenticated()
      .then(isAuthenticated => {
        if(isAuthenticated){
          console.log('is authenticated?', isAuthenticated);
        }
        else {
          auth.login();
        }
      });

    const {token, email, lang} = this.getUserInfo();

    if(lang) {
      _language.setCurrent(lang);
    }

    if (!token || !email)
      return false;
    
    const url = `${this.getUrlNeogig('https://neogig-api.neogig.com.br')}/api/v1/cliente/GetClienteConfiguracao`;
    const service = new HttpService();
    service.http.get(url)
        .then(({data}) => {
          return true;
        }).catch(err => {
      return false;
    });
    return true;
  }

  render() {
    const isLogged = this.isLogged();
    console.log(isLogged);

    if (_language.current === 'pt-BR') {
      return (
        <Router>
          <Switch>
            <Route exact path ="/" component={Home} />
            <Route path="/guide">
              <Route exact path="/guide" component={GuideList}/>
              <Route exact path="/guide/:guideId" component={GuideDetail}/>
            </Route>
            <Route path="/docs">
              <div className="container">
                <Header/>
                <route exact path="/callback.html" />
                <Route exact path="/docs" component={Interface}/>
                <Route exact path="/docs/suporte" component={Suporte}/>
                <Route exact path="/docs/controles/camadas" component={Camadas}/>
                <Route exact path="/docs/controles/relatorios" component={Relatorios}/>
                <Route exact path="/docs/camadas/areas" component={Areas}/>
                <Route exact path="/docs/camadas/clientes" component={Clientes}/>
                <Route
                    exact
                    path="/docs/camadas/cluster-de-vendas"
                    component={ClusterDeVendas}
                />
                <Route
                    exact
                    path="/docs/camadas/concorrentes"
                    component={Concorrentes}
                />
                <Route exact path="/docs/camadas/dnc" component={DNC}/>
                <Route
                    exact
                    path="/docs/camadas/limites-territoriais"
                    component={LimitesTerritoriais}
                />
                <Route exact path="/docs/camadas/mapas" component={Mapas}/>
                <Route exact path="/docs/camadas/rede" component={Rede}/>
                <Route exact path="/docs/camadas/venda-direta" component={VendaDireta}/>
                <Route exact path="/docs/camadas/voronoi" component={Voronoi}/>
                <Route
                    exact
                    path="/docs/relatorios/market-breakdown"
                    component={MarketBreakdown}
                />
                <Route
                    exact
                    path="/docs/relatorios/cesta-de-comparacao"
                    component={CestaDeComparacao}
                />
                <Route
                    exact
                    path="/docs/relatorios/emplacamento-por-bs"
                    component={EmplacamentoPorBS}
                />
                <Route
                    exact
                    path="/docs/relatorios/segmento-modelo-versao"
                    component={SegmentoModeloVersao}
                />
                <Sidebar language={_language.current}/>
              </div>
            </Route>
          </Switch>
        </Router>
      );
    } else if (_language.current === 'en-US') {
      return (
          <Router>
            <div className="container">
              <Header/>
              <Route exact path="/docs" component={InterfaceEnglish}/>
              <Route exact path="/docs/support" component={Support}/>
              <Route exact path="/docs/controls/layers" component={Layers}/>
              <Route exact path="/docs/controls/reports" component={Reports}/>
              <Route exact path="/docs/layers/areas" component={AreasEnglish}/>
              <Route exact path="/docs/layers/clients" component={Clients}/>
              <Route
                  exact
                  path="/docs/layers/sales-cluster"
                  component={SalesCluster}
              />
              <Route exact path="/docs/layers/competitors" component={Competitors}/>
              <Route exact path="/docs/layers/umd" component={UMD}/>
              <Route
                  exact
                  path="/docs/layers/territorial-boundaries"
                  component={TerritorialBoundaries}
              />
              <Route exact path="/docs/layers/maps" component={Maps}/>
              <Route exact path="/docs/layers/network" component={Network}/>
              <Route exact path="/docs/layers/direct-sales" component={DirectSales}/>
              <Route exact path="/docs/layers/voronoi" component={VoronoiEnglish}/>
              <Route
                  exact
                  path="/docs/reports/market-breakdown"
                  component={MarketBreakdownEnglish}
              />
              <Route
                  exact
                  path="/docs/reports/comparison-basket"
                  component={ComparisonBasket}
              />
              <Route
                  exact
                  path="/docs/reports/registration-by-bs"
                  component={RegistrationByBS}
              />
              <Route
                  exact
                  path="/docs/reports/segment-model-version"
                  component={SegmentModelVersion}
              />
              <Sidebar language={_language.current}/>
            </div>
          </Router>
      );
    } else {
      return (
          <div>
            <p style={{textAlign: "center", paddingTop: 50}}>
              <span style={{color: "tomato"}}>Erro: </span>
              Nenhuma língua selecionada.
            </p>
          </div>
      );
    }
  }
}

export default App;
