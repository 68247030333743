import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import UMDVideo from "../../../../assets/pages/layers/umd/umd.mp4";
import UMDConcept from "../../../../assets/pages/layers/umd/umd-concept.mp4";

// Image
import ComparisonBasket from "../../../../assets/pages/layers/umd/cesta-de-comparacao.png";

function UMD() {
  return (
    <section>
      <h1>Uncaptured Demand</h1>
      <p>This layer helps you view the market opportunity in a region.</p>
      <VideoRendering>
        <source src={UMDVideo} type="video/mp4" />
      </VideoRendering>
      <p>
        Uncaptured Demand, or UMD, indicates the potential increase, using the
        group's average performance in comparable markets as a reference, which
        can be achieved by the brand in a market.
      </p>
      <h2>Methodology</h2>
      <p>
        The comparison basket, which is composed of comparable regions to the
        selected region, is initially determined.
      </p>
      <img class="center" src={ComparisonBasket} alt="" />
      <p>
        The average basket’s registration market share is compared to the
        selected region’s average and, thus, it is possible to see if there is a
        market opportunity to be reached.
      </p>
      <VideoRendering>
        <source src={UMDConcept} type="video/mp4" />
      </VideoRendering>
      <p>
        Therefore, it is noted that the understanding of the calculation formula
        of <b>Uncaptured Demand</b> is simple. It is equal to{" "}
        <b>Industry Volume</b> multiplied by the difference between the{" "}
        <b>Potential Market Share</b> and the{" "}
        <b>Market Share Held by the Brand</b> in a region.
      </p>
    </section>
  );
}

export default UMD;
