import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import SegmentModelVersion from "../../../../assets/pages/reports/segment-model-version.mp4";

function SegmentoModeloVersao() {
  return (
    <section>
      <h1>Segmento, Modelo e Versão</h1>
      <p>
        Este relatório permite a exportação de uma tabela completa com os dados
        de emplacamento anual por bandeira, tipo de venda, segmento de produto,
        modelo e versão do automóvel em uma região.
      </p>
      <VideoRendering>
        <source src={SegmentModelVersion} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default SegmentoModeloVersao;
