import React, { Component } from "react";
import Logo from "../assets/logo.svg";

class Header extends Component {
  render() {
    return (
      <header>
        <div className="display-center">
          <img className="logo" title="GIG Manual" src={Logo} alt="" />
        </div>
      </header>
    );
  }
}

export default Header;
