import React from "react";
import VideoRendering from "../../video-rendering";

// Video
import Support from "../../../assets/pages/support/support.mp4";
import Tour from "../../../assets/pages/support/tour.mp4";

function Suporte() {
  return (
    <section>
      <h1>Suporte</h1>
      <p>
        O suporte do GIG foi desenvolvido, desde o início, com o intuito de
        facilitar a sua experiência com a plataforma. Com ele, é possível desde
        o aprendizado de análises com treinamentos on-line até o contato direto
        com a equipe para que sejam esclarecidas quaisquer dúvidas ou feitas
        solicitações de melhorias.
      </p>
      <h2>Plataforma</h2>
      <h3>Suporte</h3>
      <p>
        A nossa equipe está sempre à disposição para te auxiliar com quaisquer
        dúvidas ou solicitações. A sua mensagem, assim que recebida, será
        respondida o mais brevemente possível.
      </p>
      <VideoRendering>
        <source src={Support} type="video/mp4" />
      </VideoRendering>
      <h3>Tour</h3>
      <p>
        Além de estudar os manuais, é possível realizar um tour interativo pela
        plataforma. Para iniciar o tour, basta clicar no ícone Tour e apertar a
        seta para a direita do teclado, ou aguardar alguns segundos, para abrir
        novas caixas de informação.
      </p>
      <VideoRendering>
        <source src={Tour} type="video/mp4" />
      </VideoRendering>
      <h2>Equipes</h2>
      <h3>Treinamento</h3>
      <p>
        Caso exista a necessidade de treinamento, nossa equipe especializada
        está pronta para auxiliar. Os treinamentos on-line ocorrem semanalmente
        em horários distintos e a disponibilidade de lugares para participação é
        anunciada por meio da nossa newsletter.
      </p>
      <h3>Contato</h3>
      <p>
        Além das áreas de contato disponíveis, é possível entrar em contato
        conosco diretamente pelo endereço de e-mail{" "}
        <a href="mailto:suportegig@neocom.info">
          <strong>suportegig@neocom.info</strong>
        </a>
        . A sua mensagem, assim que enviada, será recebida pela nossa equipe e
        respondida o mais brevemente possível. Caso prefira contato telefônico,
        a equipe de suporte está disponível através do número{" "}
        <a href="tel:+556132483248">
          <strong>+55 (61) 3248-3248</strong>
        </a>
        .
      </p>
    </section>
  );
}

export default Suporte;
