import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import Tabular from "../../../../assets/tabular.svg";

// Video
import FirstPage from "../../../../assets/pages/reports/registration-by-bs/first-page.mp4";
import SecondPage from "../../../../assets/pages/reports/registration-by-bs/second-page.mp4";

function EmplacamentoPorBS() {
  return (
    <section>
      <h1>Emplacamento por Bandeira e Segmento</h1>
      <p>
        Este relatório permite a visualização do histórico de emplacamentos em
        determinada região por segmento de produto e por bandeira.
      </p>
      <h3>Primeira Página</h3>
      <p>
        Com ela, é possível observar o{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          número de emplacamentos
        </span>{" "}
        da indústria e da sua marca, além do{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          market share de emplacamento
        </span>{" "}
        da sua marca por segmento de produto na região e período selecionados.
      </p>
      <VideoRendering>
        <source src={FirstPage} type="video/mp4" />
      </VideoRendering>
      <h3>Segunda Página</h3>
      <p>
        Com ela, é possível observar o{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          número de emplacamentos
        </span>{" "}
        por bandeira na região e período selecionados.
      </p>
      <VideoRendering>
        <source src={SecondPage} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default EmplacamentoPorBS;
