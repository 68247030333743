import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";
import PieChart from "../../../../assets/pie-chart.svg";
import Tabular from "../../../../assets/tabular.svg";

// Video
import ClientsVideo from "../../../../assets/pages/layers/clients.mp4";

function Clients() {
  return (
    <section>
      <h1>Clients</h1>
      <p>
        This layer helps you view the customer's location by dealer or group in
        a market.
      </p>
      <VideoRendering>
        <source src={ClientsVideo} type="video/mp4" />
      </VideoRendering>
      <p>
        The visualization of the data below is done with the aid of the{" "}
        <a href="/controls/layers#display-data">
          <strong>Display Data</strong>
        </a>{" "}
        control of this layer.
      </p>
      <h3>Top 10 Dealers</h3>
      <p>
        The{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          customer market share{" "}
        </span>
        and{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of customers
        </span>{" "}
        of the top ten dealers with the highest performance in the selected
        regions and period.
      </p>
      <h3>Summary</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          number of clients
        </span>{" "}
        of different dealerships and their respective{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          registration market share
        </span>{" "}
        in the selected regions and period.
      </p>
    </section>
  );
}

export default Clients;
