import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";

// Video
import RegistrationAnalysis from "../../../../assets/pages/reports/market-breakdown/registration-analysis.mp4";
import SalesAnalysis from "../../../../assets/pages/reports/market-breakdown/sales-analysis.mp4";

function MarketBreakdownEnglish() {
  return (
    <section>
      <h1>Market Breakdown</h1>
      <p>
        This report provides the most relevant data for registration analysis
        and sales in an area. This report has a specific format that allows a
        greater intuition about the relationship between this data.
      </p>
      <h3>Registration Analysis</h3>
      <p>
        You can observe not only the industry's{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of registrations
        </span>{" "}
        and your brand's, but also the{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          registration market share
        </span>{" "}
        and the industry’s{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of outlets
        </span>{" "}
        and your brand's in each area.
      </p>
      <VideoRendering>
        <source src={RegistrationAnalysis} type="video/mp4" />
      </VideoRendering>
      <h3>Sales Analysis</h3>
      <p>
        You can observe not only the industry’s{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of sales
        </span>{" "}
        and your brand's, but also the{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          sales pitch
        </span>{" "}
        of the areas and the industry's{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of outlets
        </span>{" "}
        and your brand's in each area.
      </p>
      <VideoRendering>
        <source src={SalesAnalysis} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default MarketBreakdownEnglish;
