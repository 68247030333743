import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import Maps from "../../../../assets/pages/layers/maps.mp4";

function Mapas() {
  return (
    <section>
      <h1>Mapas</h1>
      <p>
        Esta camada permite a visualização de diferentes mapas temáticos para
        acompanhar análises.
      </p>
      <VideoRendering>
        <source src={Maps} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Mapas;
