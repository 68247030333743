import React from "react";
import VideoRendering from "../../video-rendering";

// Video
import SearchBar from "../../../assets/pages/interface/search-bar.mp4";
import Layers from "../../../assets/pages/interface/layers.mp4";
import Reports from "../../../assets/pages/interface/reports.mp4";
import SelectionTool from "../../../assets/pages/interface/tools/selection-tool.mp4";
import AddMap from "../../../assets/pages/interface/tools/add-map.mp4";
import ScreenCapture from "../../../assets/pages/interface/tools/screen-capture.mp4";

function InterfaceEnglish() {
  return (
    <section>
      <h1>Interface</h1>
      <p>
        The interface integrates the platform's features and parameters that
        helps you view relevant data.
      </p>
      <h2>Search Bar</h2>
      <p>
        The search bar helps you search and delimit locale, either by your
        country's official geographical limits or by your brand's market
        geometries, in the thematic map.
      </p>
      <VideoRendering>
        <source src={SearchBar} type="video/mp4" />
      </VideoRendering>
      <h2>Thematic Map</h2>
      <p>
        The thematic map helps you view the market data graphically. It brings
        new insights about your position and your competitor's in an
        uncomplicated way.
      </p>
      <h2>Layers</h2>
      <p>
        The layers help you select the datasets to be displayed in the thematic
        map. A thorough understanding of the different layers will allow you to
        create detailed analyses on any locale.
      </p>
      <VideoRendering>
        <source src={Layers} type="video/mp4" />
      </VideoRendering>
      <h2>Reports</h2>
      <p>
        The reports help you observe data that do not rely on the thematic map
        in order to facilitate the data query for specific analyses. Many
        reports are printed or shared to support decision-making.
      </p>
      <VideoRendering>
        <source src={Reports} type="video/mp4" />
      </VideoRendering>
      <h2>Tools</h2>
      <p>
        The tools bring new possibilities to explore the data in the thematic
        map or to share them. With them, the selection of a specific area or the
        capture of every data present in the thematic map has never been easier.
      </p>
      <h3>Selection</h3>
      <p>
        The selection tool helps the analyst create an arbitrary area in order
        to view your brand's customer data and dealership's data.
      </p>
      <VideoRendering>
        <source src={SelectionTool} type="video/mp4" />
      </VideoRendering>
      <h3>Add Map</h3>
      <p>
        Comparing different data in the thematic map can be valuable to an
        analysis. With this tool, adding a second map is two clicks away.
      </p>
      <VideoRendering>
        <source src={AddMap} type="video/mp4" />
      </VideoRendering>
      <h3>Screen Capture</h3>
      <p>
        Data from an analysis can enrich a presentation or be useful to someone
        else. With this tool, capturing the thematic map is just one click away.
      </p>
      <VideoRendering>
        <source src={ScreenCapture} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default InterfaceEnglish;
