import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";
// Video
import RegistrationAnalysis from "../../../../assets/pages/reports/market-breakdown/registration-analysis.mp4";
import SalesAnalysis from "../../../../assets/pages/reports/market-breakdown/sales-analysis.mp4";

function MarketBreakdown() {
  return (
    <section>
      <h1>Market Breakdown</h1>
      <p>
        Este relatório traz os dados mais relevantes para a análise de
        emplacamento e venda em determinada área. Este relatório possui uma
        formatação específica que permite uma maior intuição sobre a relação
        entre estes dados.
      </p>
      <h3>Análise de Emplacamento</h3>
      <p>
        Pode-se observar não só o{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de emplacamentos
        </span>{" "}
        da indústria e da sua marca, mas também o seu{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          market share de emplacamento
        </span>{" "}
        e o{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de outlets
        </span>{" "}
        da indústria e da sua marca em cada área.
      </p>
      <VideoRendering>
        <source src={RegistrationAnalysis} type="video/mp4" />
      </VideoRendering>
      <h3>Análise de Venda</h3>
      <p>
        Pode-se observar não só o{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de vendas
        </span>{" "}
        da indústria e da sua marca, mas também a{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          vocação de vendas
        </span>{" "}
        das áreas e o{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de outlets
        </span>{" "}
        da indústria e da sua marca em cada área.
      </p>
      <VideoRendering>
        <source src={SalesAnalysis} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default MarketBreakdown;
