import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";

// Video
import ComparisonBasket from "../../../../assets/pages/reports/comparison-basket/comparison-basket.mp4";
import ComparisonBasketBySegment from "../../../../assets/pages/reports/comparison-basket/comparison-basket-by-segment.mp4";

function CestaDeComparacao() {
  return (
    <section>
      <h1>Cesta de Comparação</h1>
      <p>
        Este relatório permite a visualização da oportunidade de mercado de uma
        bandeira, em determinada região, representada pela sua{" "}
        <a href="/camadas/dnc">
          <strong>Demanda Não Capturada</strong>
        </a>
        .
      </p>
      <h3>Análise da Cesta de Comparação</h3>
      <p>
        Pode-se observar a cesta de comparação da região selecionada e a sua
        média de{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          market share de emplacamento
        </span>
        , possibilitando o reconhecimento da sua oportunidade de mercado.
      </p>
      <VideoRendering>
        <source src={ComparisonBasket} type="video/mp4" />
      </VideoRendering>
      <h3>Análise da Cesta de Comparação Por Segmento</h3>
      <p>
        É possível visualizar também os dados do relatório filtrados por
        segmento. Basta selecionar quaisquer segmentos presentes abaixo da cesta
        e ela será modificada.
      </p>
      <VideoRendering>
        <source src={ComparisonBasketBySegment} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default CestaDeComparacao;
