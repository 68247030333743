import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import MapsVideo from "../../../../assets/pages/layers/maps.mp4";

function Maps() {
  return (
    <section>
      <h1>Maps</h1>
      <p>
        This layer help you view the different thematic maps that can go along
        with analyses.
      </p>
      <VideoRendering>
        <source src={MapsVideo} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Maps;
