import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import VoronoiVideo from "../../../../assets/pages/layers/voronoi/voronoi.mp4";
import VoronoiByGroup from "../../../../assets/pages/layers/voronoi/voronoi-by-group.mp4";

function Voronoi() {
  return (
    <section>
      <h1>Voronoi</h1>
      <p>
        Esta camada permite a visualização da divisão de um território em
        regiões cujas limitações são as distâncias médias entre as
        concessionárias.
      </p>
      <VideoRendering>
        <source src={VoronoiVideo} type="video/mp4" />
      </VideoRendering>
      <p>
        Os clientes costumam buscar a concessionária mais próxima para compras e
        serviços. O diagrama de Voronoi é utilizado na identificação da área de
        atendimento prioritário de uma concessionária.
      </p>
      <h3>Por Grupo</h3>
      <p>
        Este controle permite a análise da área de atendimento mais provável de
        Dealer Groups.
      </p>
      <VideoRendering>
        <source src={VoronoiByGroup} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Voronoi;
