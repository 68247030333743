import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";
import PieChart from "../../../../assets/pie-chart.svg";
import Tabular from "../../../../assets/tabular.svg";

// Video
import Clients from "../../../../assets/pages/layers/clients.mp4";

function Clientes() {
  return (
    <section>
      <h1>Clientes</h1>
      <p>
        Esta camada permite a visualização da localização de clientes por
        concessionária ou grupo em um mercado.
      </p>
      <VideoRendering>
        <source src={Clients} type="video/mp4" />
      </VideoRendering>
      <p>
        A visualização dos dados abaixo é feita com o auxílio do controle{" "}
        <a href="/controles/camadas#display-data">
          <strong>Visualizar Dados</strong>
        </a>{" "}
        desta camada.
      </p>
      <h3>Top 10 Dealers</h3>
      <p>
        O{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          market share de cliente
        </span>{" "}
        e o{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de clientes
        </span>{" "}
        das dez concessionárias com maior desempenho nas regiões e período
        selecionados.
      </p>
      <h3>Sumário</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          número de clientes
        </span>{" "}
        das diferentes concessionárias e o respectivo{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          market share de cliente
        </span>{" "}
        nas regiões e período selecionados.
      </p>
    </section>
  );
}

export default Clientes;
