import { getCountry } from '../utils';

const country = getCountry();

export const env = {
  NODE_ENV: 'production',
  URL: {
    MANUAL_URL: `https://manual.neogig.com.${country}/docs`,
    IDENTITY_SERVER_URL: `https://identity-server.neogig.com.${country}`,
  },
};
