import React, { useState, useEffect } from "react";
import Arrow from "../assets/arrow.svg";

function Sidebar(props) {
  const [controlsVisibility, setControlsVisibility] = useState(() => {
    const localData = localStorage.getItem("controlsVisibility");
    return localData ? JSON.parse(localData) : "";
  });

  const [layersVisibility, setLayersVisibility] = useState(() => {
    const localData = localStorage.getItem("layersVisibility");
    return localData ? JSON.parse(localData) : "";
  });

  const [reportsVisibility, setReportsVisibility] = useState(() => {
    const localData = localStorage.getItem("reportsVisibility");
    return localData ? JSON.parse(localData) : "";
  });

  useEffect(() => {
    localStorage.setItem(
      "controlsVisibility",
      JSON.stringify(controlsVisibility)
    );
  }, [controlsVisibility]);

  useEffect(() => {
    localStorage.setItem("layersVisibility", JSON.stringify(layersVisibility));
  }, [layersVisibility]);

  useEffect(() => {
    localStorage.setItem(
      "reportsVisibility",
      JSON.stringify(reportsVisibility)
    );
  }, [reportsVisibility]);

  if (props.language === "pt-BR") {
    return (
      <div className="sidebar">
        <ul>
          <a href="/docs">
            <li className="list-header-no-icon">Interface</li>
          </a>
          <li
            onClick={() => setControlsVisibility(!controlsVisibility)}
            className="list-header"
          >
            <img
              className={controlsVisibility ? "arrow-down" : "arrow-right"}
              src={Arrow}
              alt=""
            />{" "}
            Controles
          </li>
          <ul
            className={
              controlsVisibility ? "nested-list" : "nested-list-hidden"
            }
          >
            <a href="/docs/controles/camadas">
              <li>Camadas</li>
            </a>
            <a href="/docs/controles/relatorios">
              <li>Relatórios</li>
            </a>
          </ul>
          <li
            onClick={() => setLayersVisibility(!layersVisibility)}
            className="list-header"
          >
            <img
              className={layersVisibility ? "arrow-down" : "arrow-right"}
              src={Arrow}
              alt=""
            />{" "}
            Camadas
          </li>
          <ul
            className={layersVisibility ? "nested-list" : "nested-list-hidden"}
          >
            <a href="/docs/camadas/mapas">
              <li>Mapas</li>
            </a>
            <a href="/docs/camadas/rede">
              <li>Rede</li>
            </a>
            <a href="/docs/camadas/concorrentes">
              <li>Concorrentes</li>
            </a>
            <a href="/docs/camadas/cluster-de-vendas">
              <li>Cluster de Vendas</li>
            </a>
            <a href="/docs/camadas/clientes">
              <li>Clientes</li>
            </a>
            <a href="/docs/camadas/venda-direta">
              <li>Venda Direta</li>
            </a>
            <a href="/docs/camadas/voronoi">
              <li>Voronoi</li>
            </a>
            <a href="/docs/camadas/dnc">
              <li>DNC</li>
            </a>
            <a href="/docs/camadas/areas">
              <li>Áreas</li>
            </a>
            <a href="/docs/camadas/limites-territoriais">
              <li>Limites Territoriais</li>
            </a>
          </ul>
          <li
            onClick={() => setReportsVisibility(!reportsVisibility)}
            className="list-header"
          >
            <img
              className={reportsVisibility ? "arrow-down" : "arrow-right"}
              src={Arrow}
              alt=""
            />{" "}
            Relatórios
          </li>
          <ul
            className={reportsVisibility ? "nested-list" : "nested-list-hidden"}
          >
            <a href="/docs/relatorios/market-breakdown">
              <li>Market Breakdown</li>
            </a>
            <a href="/docs/relatorios/cesta-de-comparacao">
              <li>Cesta de Comparação</li>
            </a>
            <a href="/docs/relatorios/emplacamento-por-bs">
              <li>Emplacamento por Bandeira e Segmento</li>
            </a>
            <a href="/docs/relatorios/segmento-modelo-versao">
              <li>Segmento, Modelo e Versão</li>
            </a>
          </ul>
          <a href="/docs/suporte">
            <li className="list-header-no-icon">Suporte</li>
          </a>
        </ul>
      </div>
    );
  }

  if (props.language === "en-US") {
    return (
      <div className="sidebar">
        <ul>
          <a href="/docs/">
            <li className="list-header-no-icon">Interface</li>
          </a>
          <li
            onClick={() => setControlsVisibility(!controlsVisibility)}
            className="list-header"
          >
            <img
              className={controlsVisibility ? "arrow-down" : "arrow-right"}
              src={Arrow}
              alt=""
            />{" "}
            Controls
          </li>
          <ul
            className={
              controlsVisibility ? "nested-list" : "nested-list-hidden"
            }
          >
            <a href="/docs/controls/layers">
              <li>Layers</li>
            </a>
            <a href="/docs/controls/reports">
              <li>Reports</li>
            </a>
          </ul>
          <li
            onClick={() => setLayersVisibility(!layersVisibility)}
            className="list-header"
          >
            <img
              className={layersVisibility ? "arrow-down" : "arrow-right"}
              src={Arrow}
              alt=""
            />{" "}
            Layers
          </li>
          <ul
            className={layersVisibility ? "nested-list" : "nested-list-hidden"}
          >
            <a href="/docs/layers/maps">
              <li>Maps</li>
            </a>
            <a href="/docs/layers/network">
              <li>Network</li>
            </a>
            <a href="/docs/layers/competitors">
              <li>Competitors</li>
            </a>
            <a href="/docs/layers/sales-cluster">
              <li>Sales Cluster</li>
            </a>
            <a href="/docs/layers/clients">
              <li>Clients</li>
            </a>
            <a href="/docs/layers/direct-sales">
              <li>Direct Sales</li>
            </a>
            <a href="/docs/layers/voronoi">
              <li>Voronoi</li>
            </a>
            <a href="/docs/layers/umd">
              <li>UMD</li>
            </a>
            <a href="/docs/layers/areas">
              <li>Areas</li>
            </a>
            <a href="/docs/layers/territorial-boundaries">
              <li>Territorial Boundaries</li>
            </a>
          </ul>
          <li
            onClick={() => setReportsVisibility(!reportsVisibility)}
            className="list-header"
          >
            <img
              className={layersVisibility ? "arrow-down" : "arrow-right"}
              src={Arrow}
              alt=""
            />{" "}
            Reports
          </li>
          <ul
            className={reportsVisibility ? "nested-list" : "nested-list-hidden"}
          >
            <a href="/docs/reports/market-breakdown">
              <li>Market Breakdown</li>
            </a>
            <a href="/docs/reports/comparison-basket">
              <li>Comparison Basket</li>
            </a>
            <a href="/docs/reports/registration-by-bs">
              <li>Registration by Brand and Segment</li>
            </a>
            <a href="/docs/reports/segment-model-version">
              <li>Segment, Model and Version</li>
            </a>
          </ul>
          <a href="/docs/support">
            <li className="list-header-no-icon">Support</li>
          </a>
        </ul>
      </div>
    );
  }
}

export default Sidebar;
