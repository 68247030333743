import React from 'react';
import DocsIcon from "../assets/docs_icon.png";
import GuideIcon from "../assets/guide_icon.png";

function Home() {
  return (
    <div className="home-container">
      <img src="https://www.neogig.com.br/static/images/gig_logo.png" alt="" />
      <div className="home">
        <a href="/docs">
          <img src={DocsIcon} alt="" />
          <p><h1>Docs</h1>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
        </a>
        <a href="/guide">
          <img src={GuideIcon} alt="" />
          <p><h1>Guide</h1>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
        </a>
      </div>
    </div>
  );
}

export default Home;