import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import EnvironmentOpening from "../../../../assets/pages/controls/reports/environment-opening.mp4";
import DataFilters from "../../../../assets/pages/controls/reports/data-filters.mp4";
import SimpleExportToExcel from "../../../../assets/pages/controls/reports/simple-export-to-excel.mp4";
import DetailedExportToExcel from "../../../../assets/pages/controls/reports/detailed-export-to-excel.mp4";
import ExportToPDF from "../../../../assets/pages/controls/reports/export-to-pdf.mp4";
import Printing from "../../../../assets/pages/controls/reports/printing.mp4";

function Reports() {
  return (
    <section>
      <h1>Reports</h1>
      <p>
        Each report helps you view distinct data to be covered later. They are
        within the same environment and their controls, detailed below, are very
        similar.
      </p>
      <h2>General Controls</h2>
      <h3>Environment Opening</h3>
      <p>
        Reports are available in a different environment. To access it, click
        the icon in the navigation bar.
      </p>
      <VideoRendering>
        <source src={EnvironmentOpening} type="video/mp4" />
      </VideoRendering>
      <h3>Data Filters</h3>
      <p>
        Every report consists of a few parameters, such as area, sales type,
        period, among others. With this control, you can filter the values of
        each parameter.
      </p>
      <VideoRendering>
        <source src={DataFilters} type="video/mp4" />
      </VideoRendering>
      <h2>Specific Controls</h2>
      <h3>Simple Export to Excel</h3>
      <p>
        If the visualization of the essential information present in the report
        is sufficient, it is possible to export it with the help of this
        control.
      </p>
      <VideoRendering>
        <source src={SimpleExportToExcel} type="video/mp4" />
      </VideoRendering>
      <h3>Detailed Export to Excel</h3>
      <p>
        If a more detailed view of the information in the report is required, it
        is possible to export it with the help of this control.
      </p>
      <VideoRendering>
        <source src={DetailedExportToExcel} type="video/mp4" />
      </VideoRendering>
      <h3>Export to PDF</h3>
      <p>
        If needed, the report can be exported to PDF. Thus, saving specific data
        for a meeting and sharing it becomes extremely convenient.
      </p>
      <VideoRendering>
        <source src={ExportToPDF} type="video/mp4" />
      </VideoRendering>
      <h3>Printing</h3>
      <p>
        If needed, the report can be printed. This, taking specific data to a
        meeting and sharing it becomes extremely convenient.
      </p>
      <VideoRendering>
        <source src={Printing} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Reports;
