import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";
import PieChart from "../../../../assets/pie-chart.svg";
import Tabular from "../../../../assets/tabular.svg";

// Video
import TerritorialBoundariesVideo from "../../../../assets/pages/layers/territorial-boundaries.mp4";

function TerritorialBoundaries() {
  return (
    <section>
      <h1>Territorial Boundaries</h1>
      <p>
        This layer helps you view data considering the official geometries of
        your country in the thematic map.
      </p>
      <VideoRendering>
        <source src={TerritorialBoundariesVideo} type="video/mp4" />
      </VideoRendering>
      <p>
        The visualization of the data below is done in three ways, as described
        below, with the{" "}
        <a href="/controls/layers#display-data">
          <strong>Display Data</strong>
        </a>{" "}
        control of this layer.
      </p>
      <h2>Industry's Registration</h2>
      <h3>Market Share Ranking</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          registration market share
        </span>{" "}
        of multiple brands in the selected region and period.
      </p>
      <h3>Registration Ranking</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of registrations
        </span>{" "}
        of multiple brands in the selected region and period.
      </p>
      <h3>Ranking by Segment</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          registration market share
        </span>{" "}
        of multiple brands by segment, filtered by your brand's segments, in the
        selected region and period.
      </p>
      <h3>Areas</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          number of registrations
        </span>{" "}
        of multiple brands and their{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          registration market share
        </span>{" "}
        in the selected region and period.
      </p>
      <h2>Brand's Registration</h2>
      <h3>Market Share Ranking</h3>
      <p>
        The ranking of your brand’s{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          registration market share
        </span>{" "}
        in the selected region and period.
      </p>
      <h3>Registration Ranking</h3>
      <p>
        The ranking of your brand's{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of registrations
        </span>{" "}
        in the selected region and period.
      </p>
      <h3>Ranking by Segment</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          registration market share
        </span>{" "}
        of the different your brand's segments in the selected regions and
        period.
      </p>
      <h3>Areas</h3>
      <p>
        The ranking of your brand's{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          number of registrations
        </span>{" "}
        and its{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          registration market share
        </span>{" "}
        in the selected region and period.
      </p>
      <h2>Market Share</h2>
      <h3>Market Share Ranking and Brand's Area</h3>
      <p>
        The ranking of your brand's registration market share is displayed in{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          pie chart
        </span>
        ,{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          bar chart
        </span>{" "}
        and{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          tabular
        </span>{" "}
        form in the different regions and period selected.
      </p>
    </section>
  );
}

export default TerritorialBoundaries;
