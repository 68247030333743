import React from 'react';

function GuideList() {
  return (
    <div className="guide-container guide-list">
      <h2>Guias</h2>
      <div className="video-list">
        <a href="/guide/video-1">
          <div className="video-list__item">
            <img className="video-list__item__img" src="https://via.placeholder.com/220x120.png?text=Video+Thumb" alt=""/>
            <div className="video-list__item__info">
              <h3>Título do vídeo</h3>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
            </div>
          </div>
        </a>
        <a href="/guide/video-2">
          <div className="video-list__item">
            <img className="video-list__item__img" src="https://via.placeholder.com/220x120.png?text=Video+Thumb" alt=""/>
            <div className="video-list__item__info">
              <h3>Título do vídeo</h3>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
            </div>
          </div>
        </a>
        <a href="/guide/video-3">
          <div className="video-list__item">
            <img className="video-list__item__img" src="https://via.placeholder.com/220x120.png?text=Video+Thumb" alt=""/>
            <div className="video-list__item__info">
              <h3>Título do vídeo</h3>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default GuideList;