import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";
import PieChart from "../../../../assets/pie-chart.svg";
import Tabular from "../../../../assets/tabular.svg";

// Video
import AreasVideo from "../../../../assets/pages/layers/areas.mp4";

function Areas() {
  return (
    <section>
      <h1>Áreas</h1>
      <p>
        Esta camada permite a visualização de dados haja vista as geometrias
        mercadológicas da bandeira no mapa temático.
      </p>
      <VideoRendering>
        <source src={AreasVideo} type="video/mp4" />
      </VideoRendering>
      <p>
        A visualização dos dados é feita de três formas, como descritas abaixo,
        com o auxílio do controle{" "}
        <a href="/controles/camadas#display-data">
          <strong>Visualizar Dados</strong>
        </a>{" "}
        desta camada.
      </p>
      <h2>Emplacamento da Indústria</h2>
      <h3>Ranking de Market Share</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          market share de emplacamento
        </span>{" "}
        das diferentes bandeiras nas regiões e período selecionados.
      </p>
      <h3>Ranking de Emplacamento</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de emplacamentos
        </span>{" "}
        das diferentes bandeiras nas regiões e período selecionados.
      </p>
      <h3>Ranking de Segmento</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          market share de emplacamento
        </span>{" "}
        dos diferentes segmentos, filtrados pelos segmentos da sua bandeira, nas
        regiões e período selecionados.
      </p>
      <h3>Áreas</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          número de emplacamentos
        </span>{" "}
        das diferentes bandeiras e o respectivo{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          market share de emplacamento
        </span>{" "}
        nas regiões e período selecionados.
      </p>
      <h2>Emplacamento da Bandeira</h2>
      <h3>Ranking de Market Share</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          market share de emplacamento
        </span>{" "}
        da sua bandeira nas regiões e período selecionados.
      </p>
      <h3>Ranking de Emplacamento</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de emplacamentos
        </span>{" "}
        da sua bandeira nas regiões e período selecionados.
      </p>
      <h3>Ranking de Segmento</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          market share de emplacamento
        </span>{" "}
        dos diferentes segmentos da sua bandeira nas regiões e período
        selecionados.
      </p>
      <h3>Áreas</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          número de emplacamentos
        </span>{" "}
        da sua bandeira e o respectivo{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          market share de emplacamento
        </span>{" "}
        nas regiões e período selecionados.
      </p>
      <h2>Market Share</h2>
      <h3>Ranking de Market Share e Áreas da Bandeira</h3>
      <p>
        O ranking do market share da sua bandeira é exposto em forma de{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          gráfico de pizza
        </span>
        ,{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          gráfico de barra
        </span>{" "}
        e em forma{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          tabular
        </span>{" "}
        nas diferentes regiões e período selecionados.
      </p>
    </section>
  );
}

export default Areas;
