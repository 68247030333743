import { getCountry } from '../utils';

const country = getCountry();

export const env = {
  NODE_ENV: 'development',
  URL: {
    MANUAL_URL: `http://dv.manual.neogig.com.${country}/docs`,
    IDENTITY_SERVER_URL: `http://identity-server-dv.neogig.com.${country}`,
  },
};
