import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";
import PieChart from "../../../../assets/pie-chart.svg";
import Tabular from "../../../../assets/tabular.svg";

// Video
import SalesClusterVideo from "../../../../assets/pages/layers/sales-cluster.mp4";

function SalesCluster() {
  return (
    <section>
      <h1>Sales Cluster</h1>
      <p>This layer helps you view the sales concentration in a market.</p>
      <VideoRendering>
        <source src={SalesClusterVideo} type="video/mp4" />
      </VideoRendering>
      <p>
        The visualization of the data below is done with the aid of the{" "}
        <a href="/controls/layers#display-data">
          <strong>Display Data</strong>
        </a>{" "}
        control of this layer.
      </p>
      <h3>Sales Ranking</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          sales market share
        </span>{" "}
        and{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          number of sales
        </span>{" "}
        of multiple brands in the selected region and period.
      </p>
      <h3>Sales Ranking By Segment</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          sales market share
        </span>{" "}
        of multiple brands by segment, filtered by your brand's segments, in the
        selected region and period.
      </p>
      <h3>Data Table</h3>
      <p>
        The ranking of{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          number of sales
        </span>{" "}
        of multiple brands and their{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          sales market share
        </span>{" "}
        in the selected regions and period.
      </p>
    </section>
  );
}

export default SalesCluster;
