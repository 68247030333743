import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import VoronoiVideo from "../../../../assets/pages/layers/voronoi/voronoi.mp4";
import VoronoiByGroup from "../../../../assets/pages/layers/voronoi/voronoi-by-group.mp4";

function Voronoi() {
  return (
    <section>
      <h1>Voronoi</h1>
      <p>
        This layer helps you view the division of a territory into areas whose
        limitations are the average distances between dealers.
      </p>
      <VideoRendering>
        <source src={VoronoiVideo} type="video/mp4" />
      </VideoRendering>
      <p>
        Customers usually go to the nearest dealer for purchases and services.
        The Voronoi diagram is used to identify the dealer's priority service
        area.
      </p>
      <h3>By Group</h3>
      <p>
        This control allows the analysis of the most likely area of service for
        Dealer Groups.
      </p>
      <VideoRendering>
        <source src={VoronoiByGroup} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Voronoi;
