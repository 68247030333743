import { UserManager, WebStorageStateStore } from 'oidc-client';
import { CONFIG } from '../config';

export default class AuthService {
  userManager;

  constructor() {
    const { protocol, hostname } = window.location;
    let port = ':3000';

    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: CONFIG.URL.IDENTITY_SERVER_URL,
      client_id: 'Neocom.Neogig.Manual',
      redirect_uri: `${protocol}//${hostname}/callback.html`,
      response_type: 'code',
      scope: 'openid profile Neocom.Neogig.Manual Neocom.Neogig.Api',
      post_logout_redirect_uri: CONFIG.URL.MANUAL_URL,
    };

    this.userManager = new UserManager(settings);
  }

  getUser() {
    return this.userManager.getUser();
  }

  login() {
    return this.userManager.signinRedirect();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }

  revokeAccessToken() {
    return this.userManager.revokeAccessToken();
  }

  isAuthenticated() {
    return this.userManager.getUser().then((user) => {
      if (user !== null) {
        sessionStorage.setItem('username', user.profile.given_name);
        sessionStorage.setItem('token', user.access_token);
        sessionStorage.setItem('tokenType', user.token_type);
        sessionStorage.setItem('client', user.profile.IdClient);
        sessionStorage.setItem('vehicle', user.profile.IdVehicleType);

        return true;
      }
      return false;
    });
  }
}
