import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import UMD from "../../../../assets/pages/layers/umd/umd.mp4";
import UMDConcept from "../../../../assets/pages/layers/umd/umd-concept.mp4";

// Image
import ComparisonBasket from "../../../../assets/pages/layers/umd/cesta-de-comparacao.png";

function DNC() {
  return (
    <section>
      <h1>Demanda Não Capturada</h1>
      <p>
        Esta camada permite a visualização de oportunidade de mercado em uma
        região.
      </p>
      <VideoRendering>
        <source src={UMD} type="video/mp4" />
      </VideoRendering>
      <p>
        A Demanda Não Capturada, ou DNC, indica o incremento em potencial,
        utilizando como referência o desempenho médio do grupo em mercados
        comparáveis, que pode ser alcançado pela bandeira em um mercado.
      </p>
      <h2>Metodologia</h2>
      <p>
        A cesta de comparação, que é composta pelas regiões comparáveis à região
        selecionada, é determinada inicialmente.
      </p>
      <img class="center" src={ComparisonBasket} alt="" />
      <p>
        A média do market share de emplacamento da cesta de comparação, por fim,
        é comparada à da região selecionada e, deste modo, é possível avaliar a
        existência de oportunidade de mercado a ser alcançada.
      </p>
      <VideoRendering>
        <source src={UMDConcept} type="video/mp4" />
      </VideoRendering>
      <p>
        Nota-se, portanto, que a compreensão da fórmula de cálculo da Demanda
        Não Capturada é simples. Ela é igual ao <b>Volume da Indústria</b>{" "}
        multiplicado pela diferença entre o <b>Market Share Potencial</b> e o{" "}
        <b>Market Share Realizado</b> da bandeira em uma região.
      </p>
    </section>
  );
}

export default DNC;
