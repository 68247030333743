import React from "react";
import VideoRendering from "../../video-rendering";

// Video
import SupportVideo from "../../../assets/pages/support/support.mp4";
import Tour from "../../../assets/pages/support/tour.mp4";

function Support() {
  return (
    <section>
      <h1>Support</h1>
      <p>
        Our support was developed, from the beginning, aiming to make your
        learning path as effortless as possible. With it, creating an analysis
        with our online training, direct contact with the team to clarify
        doubts, improvement requests and all things in between can be done
        easily.
      </p>
      <h2>Platform</h2>
      <h3>Support</h3>
      <p>
        If you have any questions or requests, our team will be there for you.
        Your message, once received by our team, will be answered as soon as
        possible.
      </p>
      <VideoRendering>
        <source src={SupportVideo} type="video/mp4" />
      </VideoRendering>
      <h3>Tour</h3>
      <p>
        In addition to studying the user guide, you can take an interactive tour
        inside the platform. To start the tour, simply click on the Tour icon
        and press the right arrow on the keyboard, or wait a few seconds, to
        open new information boxes.
      </p>
      <VideoRendering>
        <source src={Tour} type="video/mp4" />
      </VideoRendering>
      <h2>Our Team</h2>
      <h3>Training</h3>
      <p>
        If training is required, our support team will be ready to assist you.
        Online trainings take place weekly at different times and their
        availability is announced through our newsletter.
      </p>
      <h3>Contact Us</h3>
      <p>
        In addition to the available contact areas, you can contact us directly
        at{" "}
        <a href="mailto:suportegig@neocom.info">
          <strong>suportegig@neocom.info</strong>
        </a>
        . Your message, once received by our team, will <br /> be answered as
        soon as possible. If you'd rather call us, the support team <br /> is
        available at{" "}
        <a href="tel:+556132483248">
          <strong>+55 (61) 3248-3248</strong>
        </a>
        .
      </p>
    </section>
  );
}

export default Support;
