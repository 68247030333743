// DOMAIN URL
import { getCountry } from '../utils';

export const COUNTRY = getCountry().toLowerCase();

const dicEnvironments = [
  { environment: 'prod' },
  { environment: 'qa' },
  { environment: 'dev' },
  { environment: 'local' },
];

const { environment } =
  // eslint-disable-next-line no-restricted-globals
  dicEnvironments.find((x) => location.hostname.toLowerCase() === x.domain) ||
  dicEnvironments[0];
const { env } = require(`./${environment}.env`);

export const CONFIG = {
  ...env,
};
