import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import Tabular from "../../../../assets/tabular.svg";

// Video
import ComparisonBasketVideo from "../../../../assets/pages/reports/comparison-basket/comparison-basket.mp4";
import ComparisonBasketBySegment from "../../../../assets/pages/reports/comparison-basket/comparison-basket-by-segment.mp4";

function ComparisonBasket() {
  return (
    <section>
      <h1>Comparison Basket</h1>
      <p>
        This report helps you to view the market opportunity of a brand in a
        region represented by its{" "}
        <a href="/layers/umd">
          <strong>Uncaptured Demand</strong>
        </a>
        .
      </p>
      <h3>Comparison Basket Analysis</h3>
      <p>
        With this report, it is possible to observe the comparison basket of the
        selected region and its average{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          registration market share
        </span>
        , making possible the recognition of its market opportunity.
      </p>
      <VideoRendering>
        <source src={ComparisonBasketVideo} type="video/mp4" />
      </VideoRendering>
      <h3>Comparison Basket Analysis By Segment</h3>
      <p>
        It is also possible to view the comparison basket data by segment. Just
        select any of the ones present below the basket and it will be modified.
      </p>
      <VideoRendering>
        <source src={ComparisonBasketBySegment} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default ComparisonBasket;
