import React from "react";
import VideoRendering from "../../video-rendering";
import Map from "../../../assets/pages/interface/mapa-tematico.png";

// Video
import SearchBar from "../../../assets/pages/interface/search-bar.mp4";
import Layers from "../../../assets/pages/interface/layers.mp4";
import Reports from "../../../assets/pages/interface/reports.mp4";
import SelectionTool from "../../../assets/pages/interface/tools/selection-tool.mp4";
import AddMap from "../../../assets/pages/interface/tools/add-map.mp4";
import ScreenCapture from "../../../assets/pages/interface/tools/screen-capture.mp4";

function Interface() {
  return (
    <section>
      <h1>Interface</h1>
      <p>
        A Interface integra o conjunto de recursos e parâmetros da plataforma
        que permite a visualização de dados relevantes.
      </p>
      <h2>Barra de Pesquisa</h2>
      <p>
        A barra de pesquisa permite a delimitação de localidades, sejam pelas
        geometrias oficiais do seu país, sejam pelas geometrias mercadológicas
        da sua marca, no mapa temático.
      </p>
      <VideoRendering>
        <source src={SearchBar} type="video/mp4" />
      </VideoRendering>
      <h2>Mapa Temático</h2>
      <p>
        O mapa temático permite a visualização dos dados de um mercado de forma
        gráfica. Ele traz uma nova percepção do seu posicionamento e o do seus
        concorrentes de forma descomplicada.
      </p>
      <img className="center" src={Map} alt="" />
      <h2>Camadas</h2>
      <p>
        As camadas permitem a seleção dos conjuntos de dados a ser apresentados
        no mapa temático. Um entendimento profundo das diferentes camadas
        permitirá a construção de análises detalhadas sobre qualquer localidade.
      </p>
      <VideoRendering>
        <source src={Layers} type="video/mp4" />
      </VideoRendering>
      <h2>Relatórios</h2>
      <p>
        Os relatórios permitem a observação de dados que não dependem do mapa
        temático com o intuito de facilitar a consulta de dados para análises
        específicas. Muitos relatórios são impressos ou compartilhados a fim de
        auxiliar a tomada de decisão.
      </p>
      <VideoRendering>
        <source src={Reports} type="video/mp4" />
      </VideoRendering>
      <h2>Ferramentas</h2>
      <p>
        As ferramentas trazem novas possibilidades de explorar dados com o mapa
        temático ou compartilhá-los. Com elas, é possível desde a seleção de uma
        área específica até a captura de um dado presente no mapa temático.
      </p>
      <h3>Seleção</h3>
      <p>
        A ferramenta de seleção permite a criação de uma área arbitrária pelo
        analista em busca de visualizar os dados dos clientes da sua marca e das
        concessionárias.
      </p>
      <VideoRendering>
        <source src={SelectionTool} type="video/mp4" />
      </VideoRendering>
      <h3>Adicionar Mapa</h3>
      <p>
        Comparar diferentes dados no mapa temático pode agregar muito valor a
        uma análise. Com esta ferramenta, um segundo mapa fica disponível em
        dois clique.
      </p>
      <VideoRendering>
        <source src={AddMap} type="video/mp4" />
      </VideoRendering>
      <h3>Capturar Tela</h3>
      <p>
        Os dados de uma análise podem enriquecer uma apresentação ou ser útil
        para uma outra pessoa. Com esta ferramenta, a captura do mapa temático
        pode ser feita em apenas um clique.
      </p>
      <VideoRendering>
        <source src={ScreenCapture} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Interface;
