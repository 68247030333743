import React from "react";
import VideoRendering from "../../../video-rendering";

// Icon
import BarChart from "../../../../assets/bar-chart.svg";
import PieChart from "../../../../assets/pie-chart.svg";
import Tabular from "../../../../assets/tabular.svg";

// Video
import DirectSales from "../../../../assets/pages/layers/direct-sales.mp4";

function VendaDireta() {
  return (
    <section>
      <h1>Venda Direta</h1>
      <p>
        Esta camada permite a visualização dos emplacamentos de venda direta em
        um mercado.
      </p>
      <VideoRendering>
        <source src={DirectSales} type="video/mp4" />
      </VideoRendering>
      <p>
        A visualização dos dados abaixo é feita com o auxílio do controle{" "}
        <a href="/controles/camadas#display-data">
          <strong>Visualizar Dados</strong>
        </a>{" "}
        desta camada.
      </p>
      <h3>Ranking por Bandeira</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          market share de emplacamento
        </span>{" "}
        das diferentes bandeiras nas regiões e período selecionados.
      </p>
      <h3>Ranking por Canal</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={BarChart} alt="" />
          número de emplacamentos
        </span>{" "}
        dos diferentes canais nas regiões e período selecionados.
      </p>
      <h3>Ranking por Segmento</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={PieChart} alt="" />
          market share de emplacamento
        </span>{" "}
        da indústria nos diferentes segmentos, filtrados pelos segmentos da da
        sua marca, nas regiões e período selecionados.
      </p>
      <h3>Sumário</h3>
      <p>
        O ranking do{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          número de emplacamentos
        </span>{" "}
        da indústria e o respectivo{" "}
        <span>
          <img className="chart" src={Tabular} alt="" />
          market share de emplacamento
        </span>{" "}
        nas regiões e período selecionados.
      </p>
    </section>
  );
}

export default VendaDireta;
