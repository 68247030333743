import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import SegmentModelVersionVideo from "../../../../assets/pages/reports/segment-model-version.mp4";

function SegmentModelVersion() {
  return (
    <section>
      <h1>Segment, Model and Version</h1>
      <p>
        This report helps you export a complete sheet of annual registration
        data by brand, sales type, product segment, model and car version in a
        region.
      </p>
      <VideoRendering>
        <source src={SegmentModelVersionVideo} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default SegmentModelVersion;
