import React from "react";
import VideoRendering from "../../../video-rendering";

// Video
import EnableLayer from "../../../../assets/pages/controls/layers/enable-layer.mp4";
import DisplayData from "../../../../assets/pages/controls/layers/display-data.mp4";
import DataFilters from "../../../../assets/pages/controls/layers/data-filters.mp4";
import UndoChanges from "../../../../assets/pages/controls/layers/undo-changes.mp4";
import HeatMap from "../../../../assets/pages/controls/layers/heat-map.mp4";
import Clutter from "../../../../assets/pages/controls/layers/clutter.mp4";
import Declutter from "../../../../assets/pages/controls/layers/declutter.mp4";
import Range from "../../../../assets/pages/controls/layers/range.mp4";
import Pins from "../../../../assets/pages/controls/layers/pins.mp4";
import DisplayReportedStores from "../../../../assets/pages/controls/layers/display-reported-stores.mp4";
import Period from "../../../../assets/pages/controls/layers/period.mp4";
import AverageMonthlySales from "../../../../assets/pages/controls/layers/average-monthly-sales.mp4";
import DisplayStoreName from "../../../../assets/pages/controls/layers/display-store-name.mp4";
import SelectRegion from "../../../../assets/pages/controls/layers/select-region.mp4";
import SelectData from "../../../../assets/pages/controls/layers/select-data.mp4";
import DisplayLocaleName from "../../../../assets/pages/controls/layers/display-locale-name.mp4";
import DisplayThemeValue from "../../../../assets/pages/controls/layers/display-theme-value.mp4";

function Layers() {
  return (
    <section>
      <h1>Layers</h1>
      <p>
        Each layer helps you view distinct data to be covered later. The
        controls detailed below are very similar and allow the operation and
        customization of all the layers.
      </p>
      <h2>General Controls</h2>
      <h3>Enable Layer</h3>
      <p>
        A layer must be enabled in order for its data to be displayed in the
        thematic map. With this control, enabling it is just a click away.
      </p>
      <VideoRendering>
        <source src={EnableLayer} type="video/mp4" />
      </VideoRendering>
      <h3 id="display-data">Display Data</h3>
      <p>
        Besides being displayed in the thematic map, the layer data can also be
        displayed in tabular and graphic form. With this control, displaying
        data is these two new ways is just a click away.
      </p>
      <VideoRendering>
        <source src={DisplayData} type="video/mp4" />
      </VideoRendering>
      <h3>Data Filters</h3>
      <p>
        The data of a layer can be filtered by groups, segments, among others.
        With this control, you can filter them in two clicks.
      </p>
      <VideoRendering>
        <source src={DataFilters} type="video/mp4" />
      </VideoRendering>
      <h3>Undo Changes</h3>
      <p>
        Undoing all the changes made to a layer can be essential after an
        analysis. With this control, one click is all it takes.
      </p>
      <VideoRendering>
        <source src={UndoChanges} type="video/mp4" />
      </VideoRendering>
      <h2>Details</h2>
      <p>
        There are more specific controls in all layers, to be explored below,
        that allow a more detailed view of the data displayed in the thematic
        map.
      </p>
      <h3>Heat Map</h3>
      <p>
        With this control, the data displayed in the thematic map can be
        observed according to its concentration in the region.
      </p>
      <VideoRendering>
        <source src={HeatMap} type="video/mp4" />
      </VideoRendering>
      <h3>Clutter</h3>
      <p>
        With this control, it is possible to clutter the data displayed in the
        thematic map that is decluttered.
      </p>
      <VideoRendering>
        <source src={Clutter} type="video/mp4" />
      </VideoRendering>
      <h3>Declutter</h3>
      <p>
        With this control, it is possible to declutter the data displayed in the
        thematic map that is clustered.
      </p>
      <VideoRendering>
        <source src={Declutter} type="video/mp4" />
      </VideoRendering>
      <h3>Range</h3>
      <p>
        Compared to the previous three controls, there is a remarkable range of
        intensities in which data can be represented. With this control, it is
        possible to modify the intensity.
      </p>
      <VideoRendering>
        <source src={Range} type="video/mp4" />
      </VideoRendering>
      <h3>Pins</h3>
      <p>
        With this control, is possible to resize the dealer pins in the thematic
        map.
      </p>
      <VideoRendering>
        <source src={Pins} type="video/mp4" />
      </VideoRendering>
      <h3>Display Reported Stores</h3>
      <p>
        Dealerships with inaccuracy of registration data, location or even their
        existence can be reported by users. With this control, it is possible to
        locate the reported dealers.
      </p>
      <VideoRendering>
        <source src={DisplayReportedStores} type="video/mp4" />
      </VideoRendering>
      <p>
        In the scenario above, there is no dealership member of your brand
        reported in the country.
      </p>
      <h3>Period</h3>
      <p>
        Every data displayed in the thematic map concern a given period. With
        this control, you can set it.
      </p>
      <VideoRendering>
        <source src={Period} type="video/mp4" />
      </VideoRendering>
      <h3>Average Monthly Sales</h3>
      <p>
        The sum of the amount of vehicles sold by a dealership over a given
        period allows you to view the average monthly sale, or AMS. With this
        control, you can view the average of a dealership.
      </p>
      <VideoRendering>
        <source src={AverageMonthlySales} type="video/mp4" />
      </VideoRendering>
      <h3>Display Store Name</h3>
      <p>
        With this control, you can view the name of each dealership displayed in
        the thematic map.
      </p>
      <VideoRendering>
        <source src={DisplayStoreName} type="video/mp4" />
      </VideoRendering>
      <h2>Specific Controls</h2>
      <p>
        The{" "}
        <a href="/layers/territorial-boundaries">
          <strong>Territorial Boundary</strong>
        </a>{" "}
        and your{" "}
        <a href="/layers/areas">
          <strong>brand's Areas</strong>
        </a>{" "}
        layers have controls, to be detailed below, which are extremely similar,
        but distinct from the controls of the other layers in the platform.
      </p>
      <h3>Select a Region</h3>
      <p>
        With this control, it is possible to set the region geometry to be
        displayed for analysis.
      </p>
      <VideoRendering>
        <source src={SelectRegion} type="video/mp4" />
      </VideoRendering>
      <h3>Select Data</h3>
      <p>
        With this control, it is possible to define the data to be displayed for
        analysis.
      </p>
      <VideoRendering>
        <source src={SelectData} type="video/mp4" />
      </VideoRendering>
      <h3>Display Locale Name</h3>
      <p>
        With this control, it is possible to view the name of the regions
        available in the thematic map.
      </p>
      <VideoRendering>
        <source src={DisplayLocaleName} type="video/mp4" />
      </VideoRendering>
      <h3>Display Theme Value</h3>
      <p>
        With this control, it is possible to view the values of each theme
        present in the thematic map.
      </p>
      <VideoRendering>
        <source src={DisplayThemeValue} type="video/mp4" />
      </VideoRendering>
    </section>
  );
}

export default Layers;
